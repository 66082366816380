import "../css/header.css";
import { useContext, useState, useEffect } from "react";
import { LuAlignJustify } from "react-icons/lu";
import { ThemeContext } from "../App";
import mn from "../data/icons/mongolia.png";
import eng from "../data/icons/united-kingdom.png";

const Header = ({ title, data }) => {
  const { setLanguage, theme, language } = useContext(ThemeContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isCollapsed) {
        setIsCollapsed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, [isCollapsed]);

  return (
    <div
      className='header w-100'
      style={{
        backgroundImage: "linear-gradient(" + theme[0] + "," + theme[1] + ")",
      }}>
      <nav
        id='navbar'
        className='navbar navbar-expand-md w-100 d-flex justify-content-between '>
        <a
          class='navbar-brand nav-logo mx-4 text-wrap text-center fs-6'
          href='#home'>
          {title}
        </a>
        <button
          type='button'
          className='navbar-toggler collapsed'
          onClick={toggleNavbar}
          aria-controls='navbarNav'
          aria-expanded={isCollapsed}
          aria-label='Toggle navigation'>
          <LuAlignJustify color='white' size={25} />
        </button>

        <div
          className={`collapse navbar-collapse d-md-flex justify-content-center ${
            isCollapsed ? "show" : ""
          }`}
          id='navbarNav'>
          <ul className='nav navbar-nav nav-pills d-md-flex justify-content-md-center align-items-md-center '>
            <a
              className='nav-link'
              href='#home'
              onClick={() => setIsCollapsed(false)}>
              {data.home}
            </a>
            <a
              className='nav-link'
              href='#about'
              onClick={() => setIsCollapsed(false)}>
              {data.about}
            </a>
            <a
              className='nav-link'
              href='#services'
              onClick={() => setIsCollapsed(false)}>
              {data.service}
            </a>
            <a
              className='nav-link'
              href='#team'
              onClick={() => setIsCollapsed(false)}>
              {data.team}
            </a>
            <a
              className='nav-link'
              href='#gallery'
              onClick={() => setIsCollapsed(false)}>
              {data.gallery}
            </a>
            <a
              className='nav-link'
              href='#contact'
              onClick={() => setIsCollapsed(false)}>
              {data.contactUs}
            </a>
            {language ? (
              <div
                onClick={() => {
                  setLanguage(false);
                  setIsCollapsed(false);
                }}>
                <img alt='mn' src={eng} className='languageIcon' />
              </div>
            ) : (
              <div
                onClick={() => {
                  setLanguage(true);
                  setIsCollapsed(false);
                }}>
                <img alt='eng' src={mn} className='languageIcon' />
              </div>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
